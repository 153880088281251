import React, { ReactNode } from 'react'
import { camelCase, isEmpty, isNull, upperFirst } from 'lodash'

// eslint-disable-next-line import/no-extraneous-dependencies

import { SectionLayout } from 'suf_storybook'
import loadable from '@loadable/component'
import { Query } from '../generated/gatsby.types'

const CustomHtml = loadable(() => import('./customHtmlSection'))
const Highlight = loadable(() => import('./highlightSection'))
const Showcase = loadable(() => import('./showcaseSection'))
const ColumnWrapper = loadable(() => import('./columnWrapperSection'))
const Banner = loadable(() => import('./bannerSection'))
const Events = loadable(() => import('./eventSection'))
const IconTeaser = loadable(() => import('./iconTeaserSection'))
const IconTeaserGroup = loadable(() => import('./iconTeasersSection'))
const KeyPoint = loadable(() => import('./keyPointSection'))
const Research = loadable(() => import('./researchSection'))
const KeyHighlight = loadable(() => import('./KeyHighlightSection'))
const DynamicSectionComponent = loadable(
  () => import('./dynamicSectionComponent')
)
const PageLayoutLeftOrRightColumnWithMainColumn = loadable(
  () => import('./pageLayoutLeftOrRightColumnSection')
)
const InfoBlockRichTextEditor = loadable(
  () => import('./infoBlockRichtextSection')
)
const InfoBlockMarkdownEditor = loadable(
  () => import('./infoBlockMarkdownSection')
)
const PageTeaser = loadable(() => import('./pageTeaserSection'))

export type Props = {
  __typename: string
  backgroundColor?: string
  description: string
  jumpTo?: string
  name?: string
  headingTitle?: Object
  children?: ReactNode
  component?: string
  content?: Object
  data?: Query
  index?: number
  customContent?: any
  hasWrapper?: any
}

const isWrapperNeeded = (component: string) => {
  if (
    component === 'Key Figure' ||
    component === 'Key Point' ||
    component === 'Page Teaser' ||
    component === 'Videos' ||
    component === 'Events' ||
    component === 'Custom HTML'
  ) {
    return false
  }
  return true
}

const isSectionRequired = (component?: string) => {
  if (component === 'Banner') {
    return true
  }
  return false
}

const supported = {
  CustomHtml,
  Highlight,
  Showcase,
  ColumnWrapper,
  Banner,
  Events,
  IconTeaser,
  IconTeaserGroup,
  KeyPoint,
  KeyHighlight,
  PageTeaser,
  InfoBlockRichTextEditor,
  InfoBlockMarkdownEditor,
  PageLayoutLeftOrRightColumnWithMainColumn,
  Research,
  InsightsPlus:Research
}

const dynamicSectionComponents = {
  Research,
  InsightsPlus:Research
}

export default ({
  component,
  content,
  children,
  headingTitle,
  customContent,
  jumpTo,
  name,
  backgroundColor,
  description,
  data
}: Props) => {
  const props = {
    component,
    content,
    customContent,
    jumpTo,
    backgroundColor,
    name,
    headingTitle,
    description,
    children,
    hasWrapper: isWrapperNeeded(component || '')
  }

  const Component = dynamicSectionComponents[upperFirst(camelCase(component))]
    ? DynamicSectionComponent
    : supported[upperFirst(camelCase(component))]

  const notContent = isNull(content) || isEmpty(content)

  if (!Component || notContent) {
    return null
  }

  const Section = isSectionRequired(component) ? React.Fragment : SectionLayout

  return (
    <Section {...props}>
      <Component
        data={data}
        content={
          component === 'Events' ? data?.allContentfulEvent?.nodes : content
        }
        customContent={component === 'Custom HTML' ? customContent : {}}
      />
    </Section>
  )
}
