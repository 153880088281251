import React, { ReactNode, useContext } from 'react'
import MarketoOverlay from './MarketoOverlay'
import { OverlayConstants, OverlayContext } from './OverlayContext'

export type Props = {
  children: ReactNode
  isRAC?: boolean
  slug?: string
}

const browser = typeof window !== 'undefined' && window

if (browser) {
  window.onload = () => {
    const hash = window.location.hash.substring(1)
    setTimeout(() => {
      if (hash === 'formthankyou') {
        document.querySelector(
          '.salesforce-embedded-form .form-form'
        ).style.display = 'none'
        document.querySelector(
          '.salesforce-embedded-form .form-thankyou'
        ).style.display = 'block'
      }
      document.getElementById(hash)?.scrollIntoView()
    }, 500)
  }
}

// Helmet used here to import all the scripts from the static site
export default ({ children, isRAC = false, slug = '' }: Props) => {
  const { activeOverlay, marketoFormId, toggleOverlay } =
    useContext(OverlayContext)
  const handleClick = () => toggleOverlay(OverlayConstants.SEARCH_OVERLAY)
  return (
    <div className="hasHeader header--inverted">
      <div id="back-to-top">
        {children}
        {activeOverlay === OverlayConstants.NONE ? null : (
          <div
            className="overlay__bg"
            onClick={handleClick}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}
          />
        )}
      </div>
      <MarketoOverlay
        handleCloseClick={() => toggleOverlay(OverlayConstants.NONE)}
        isOpen={activeOverlay === OverlayConstants.MARKETO}
        isRAC={isRAC}
        marketoFormId={marketoFormId}
        slug={slug}
      />
    </div>
  )
}
