import React from 'react'
import { graphql } from 'gatsby'
import SectionLayouts from '../components/sectionLayouts'
import { Query } from '../generated/gatsby.types'
import SEO from '../components/seo'
import removeEmptySections from '../lib/removeEmptySections'
import LayoutWrapper from '../components/layoutWrapper'

type Props = {
  data: Query
  pageContext: any
}

export default ({ data, pageContext }: Props) => {
  const { customContent } = pageContext
  const { contentfulLayouts } = data
  const { sectionLayouts, seoTitle, seoDescription, noIndex } =
    contentfulLayouts!
  const layoutSections = removeEmptySections(data, sectionLayouts)

  const typeName = contentfulLayouts?.__typename || ''
  return (
    <LayoutWrapper>
      <SEO
        title={seoTitle || 'Sustainable Fitch'}
        description={seoDescription}
        noIndex={noIndex}
        type={typeName}
        keywords={[`sustainable fitch`, seoTitle]}
      />
      <SectionLayouts
        data={data}
        sectionLayouts={layoutSections}
        customContent={customContent}
      />
    </LayoutWrapper>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulLayouts(slug: { eq: $slug }) {
      __typename
      id
      title
      slug
      backgroundColor
      name
      jumpTo
      noIndex
      seoTitle
      seoDescription
      sectionLayouts {
        __typename
        ... on Node {
          ...ContentfulSectionLayoutsFragment
        }
      }
    }

    allContentfulEvent(
      filter: {
        node_locale: { eq: "en-US" }
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "sustainableFitch" } } }
        }
      }
      sort: { fields: [startDate], order: ASC }
    ) {
      ...ContentfulEvents
    }
  }
`
