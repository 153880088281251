import { get, isEmpty, isNil, remove } from 'lodash'
import { Query } from '../generated/gatsby.types'
import { taxonomyFilteredContent } from '.'

export default (dataInput: Query, sectionsInput) => {
  if (isNil(sectionsInput)) return sectionsInput
  const filteredSections = [...sectionsInput]
  const filteredData = { ...dataInput }

  remove(filteredSections, (section) => {
    if (
      get(section, 'component', '') === 'Contacts' &&
      isEmpty(get(section, 'content', []))
    )
      return true

    // Need to check that these are actually dynamic sections b/c Events,
    // Videos, and Podcasts are also valid static section components and
    // we don't want to remove those sections
    if (
      get(section, 'component', '') === 'Events' &&
      get(section, '__typename') === 'ContentfulDynamicSection' &&
      isEmpty(taxonomyFilteredContent(filteredData, 'Event'))
    )
      return true

    if (
      get(section, 'component', '') === 'Podcasts' &&
      get(section, '__typename') === 'ContentfulDynamicSection' &&
      isEmpty(taxonomyFilteredContent(filteredData, 'Podcast'))
    )
      return true

    if (
      get(section, 'component', '') === 'Videos' &&
      get(section, '__typename') === 'ContentfulDynamicSection' &&
      isEmpty(taxonomyFilteredContent(filteredData, 'Video'))
    )
      return true

    if (
      get(section, 'component', '') === 'Webinars' &&
      isEmpty(taxonomyFilteredContent(filteredData, 'Webinar'))
    )
      return true

    return false
  })
  return filteredSections
}
